<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>
        <div class="rb1">
          <img src="@/assets/mx.png" class="icon" />
          <router-link to="/boss/bar_add">
            <div class="text">积分明细</div>
          </router-link>
        </div>      
      </div>
      <div class="content">
        <div class="items">
          <div class="item">
            <div class="box">
              <div class="name">
                <div class="button1 primary mt" @click="submit">10积分（¥8）</div> 
                <div class="button1 primary1 mt" @click="submit">50积分（¥40）</div>              
              </div>
              <div class="unames">
                <div class="button1 primary2 mt" @click="submit">30积分（¥25）</div>   
                <div class="button1 primary3 mt" @click="submit">100积分（¥85）</div>            
              </div>
            </div>           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    //this.query();
  },
  data() {
    return {
      integral: 168,
      selectItem: '1',
      ztitems: [
          {id:1, name: '全部'},
          {id:2, name: '入住'},
          {id:3, name: '空闲'}
      ],
      items: [
        {roomcode: '101', region: 'A区', floor: '1楼', state: true, flag1: false, flag2: false, flag3: false, flag4: false, lx: 1},
        {roomcode: '102', region: 'B区', floor: '2楼', state: false, flag1: false, flag2: false, flag3: false, flag4: false, lx: 3},
        {roomcode: '105', region: 'A区', floor: '1楼', state: false, flag1: false, flag2: false, flag3: false, flag4: false, lx: 6},
      ]
    };
  },
  methods: {
    async query() {
      /*let { data } = await this.axios.get('/ctid/tlService/selectdoor');
      this.items = data.data;*/
    },
    onItemClick(item) {
      //this.$router.push('/position/detail/' + item.client_id);
    },
    onDelete(item) {
      /*Dialog.confirm({
        message: '是否删除岗位[' + item.client_name + ']?'
      }).then(async () => {
        let { data } = await this.axios.post('/ctid/tlService/deletedoor', qs.stringify({ client_id: item.client_id }));
        if (data.flag === '0') {
          Toast('删除成功!');
          this.query();
        } else {
          Dialog({ message: data.message });
        }
      });*/
    },
    onEdit(item) {
      /*Dialog.confirm({
        message: '确认进行审批方式设置吗?'
      }).then(async () =>{       
        this.$router.push('/position/bind_post');
      });*/
    }
    
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 60px;
    color: #fff;
    font-size: 39px;
    float: left;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 35px;
      height: 35px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 210px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 35px;
      height: 35px;
      margin: 15px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
  height: 516px;
}
.bg {
  height: 469px;
}
.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    //border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    .name {
      line-height: 76px;
      overflow: hidden;
      font-size: 32px;
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .roomcode {
        margin-right: 20px;
      }
      .region {
        font-size: 26px;
        color: rgb(184, 188, 190);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        width: 260px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(136, 134, 134);
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color: rgb(136, 134, 134);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
      }
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.button1 {
  float: left;
  width: 47.7%;
  height: 200px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 200px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    //background: linear-gradient(90deg, rgba(98, 226, 230, 1) 0%, rgba(18, 129, 132, 1) 100%);  
    background: #4db2be;
    color: #fff;
  }
  &.primary1 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: #e07dab;
    color: #fff;
    float: right;
  }
  &.primary2 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: #896fb4;
    color: #fff;
  }
  &.primary3 {
    //background: linear-gradient(90deg, rgba(16, 189, 241, 1) 0%, rgba(25, 50, 159, 1) 100%);
    background: #5d7ca8;
    color: #fff;
    float: right;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
  &.rr {
    margin-right: 20px;
  }
}
</style>
